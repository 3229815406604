import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LawsView from '../views/LawsView.vue'
import EducationView from '../views/EducationView.vue'
import NewsView from '../views/NewsView.vue'
import ContactView from '../views/ContactView.vue'
import HistoryView from '../views/HistoryView.vue'
import BoardView from '../views/BoardView.vue'
import RegistrationView from '../views/RegistrationView.vue'
import DownloadRegistrationView from '../views/DownloadRegistrationView.vue'
import NewsDetail from '@/components/content/news/NewsDetail.vue'
import EducationDetail from '@/components/content/education/EducationDetail.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path:'/leyes',
    name:'leyes',
    component: LawsView
  },
  {
    path:'/education',
    name: 'education',
    component: EducationView

  },
  {
    path:'/news',
    name: 'news',
    component: NewsView

  },
  {
    path:'/education/:id',
    name: 'education-detail',
    component:EducationDetail

  },
  {
    path: '/news/:id',  // Ruta dinámica para el detalle de la noticia
    name: 'news-detail',
    component: NewsDetail
  },
  {
    path:'/contact',
    name: 'contact',
    component: ContactView

  },
  {
    path:'/history',
    name: 'history',
    component: HistoryView

  },
  {
    path:'/board',
    name: 'board',
    component: BoardView

  },
  {
    path:'/registration',
    name: 'registration',
    component: RegistrationView

  },
  {
    path:'/registrationdownload',
    name: 'registrationdownload',
    component: DownloadRegistrationView

  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

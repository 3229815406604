<template>
    <div class="bg-gray-100 py-6">
        <div class="container mx-auto px-4 ">
            <!-- Título de la sección -->
            <!-- <h2 class="text-4xl font-bold text-center mb-8">Noticias</h2> -->
            <!-- Contenedor de las tarjetas -->
            <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                <!-- Tarjeta de noticia -->
                <div
                    v-for="(noticia, index) in noticias"
                    :key="index"
                    class="bg-white rounded-lg shadow-lg overflow-hidden transform transition-transform hover:scale-105 border-b-4 border-red-500"
                >
                    <img
                        @click="getPost(noticia.id)"
                        v-if="noticia.post_image.length > 0"
                        :src="'https://api-dev-copef.vicomser.com/storage/' + noticia.post_image[0].url + noticia.post_image[0].image.name"
                        :alt="noticia.post_image[0].image.name"
                        class="w-full h-48 object-cover rounded-t-lg cursor-pointer"
                    >
                    <div class="p-6">
                        <h3 @click="getPost(noticia.id)" class="text-xl font-bold mb-3 text-gray-800 cursor-pointer">{{ noticia.title }}</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CardNews',
    data() {
        return {
            noticias: []
        }
    },
    created() {
        this.getReglament()
    },
    methods: {
        getReglament() {
            const postIdDetail = this.$route.params.id;
            this.$http.get('/posts-complete?post_category_id=4')
                .then(resp => {
                    const onlyforpost = resp.data.data.data;
                    const noticiasFiltradas = onlyforpost.filter((post, i) => i <= 3);
                    if (postIdDetail) {
                        this.noticias = noticiasFiltradas.filter(noticia => String(noticia.id) !== String(postIdDetail));
                        if(this.noticias.length > 3 ){
                            this.noticias= this.noticias.filter((post,i)=>i <= 2)
                        }
                    } else {
                        this.noticias = noticiasFiltradas;
                    }
                })
                .catch(error => {
                    console.error('Error al realizar la solicitud:', error);
                });
        },
        getPost(postId){

            this.$router.push({ name: 'news-detail', params: { id: postId }}).then(() => {
            this.$router.go(); // Recargar la página actual después de navegar a la nueva ruta
            });
        },


    }
}
</script>

<style>

</style>

<template>
    <header class="bg-gray-200 py-0 shadow-md flex justify-center">
        <div class="container mx-auto flex justify-center">
                <!-- Logo -->
            <div class="mx-3">
                <img src="@/assets/logo_copef_salta.png" width="80px" alt="Logo" >
            </div>
                <!-- Hamburger Button for Mobile -->
            <!-- <button @click="toggleMenu" class="lg:hidden text-gray-900 focus:outline-none ml-auto mr-4">
                <i class="fas fa-bars"></i>
            </button> -->

            <!-- Navigation for Desktop -->
            <nav class="hidden lg:flex lg:items-center lg:w-auto">
                <ul class="lg:flex lg:space-x-6">
                    <!-- router Inicio -->
                    <li>
                        <router-link to="/" class="block px-3 py-2 text-gray-900 hover:text-red-600 focus:text-red-600 transition duration-300"> Inicio </router-link>
                    </li>
                    <li class="relative" @mouseenter="showSubmenu(0)" @mouseleave="hideSubmenu()">
                        <div class="flex items-center">
                            <div class="block px-3 py-2 text-gray-900 hover:text-red-600 focus:text-red-600 transition duration-300"> Institucional </div>
                            <i class="fas fa-angle-down text-gray-600 mr-1 transition-transform transform" :class="{ 'rotate-180': isMouseOver }"></i>
                        </div>
                        <!-- Submenu Institucional -->
                        <ul v-if="isSubmenuVisible(0)" class="absolute top-full left-0 bg-white p-2 rounded shadow-lg border border-gray-200 z-50 ">
                            <li><router-link to="/history" class="block px-3 py-2 text-gray-900  hover:text-red-600 focus:text-red-600 transition duration-300 w-48 " > Sobre Nosotros </router-link></li>
                            <li><router-link to="/board" class="block px-3 py-2 text-gray-900  hover:text-red-600 focus:text-red-600 transition duration-300 w-48 "> Comision Directiva </router-link></li>
                            <li><router-link to="registration" class="block px-3 py-2 text-gray-900 hover:text-red-600 focus:text-red-600 transition duration-300 w-48 "> Matricula </router-link></li>
                        </ul>
                    </li>

                    <li>
                        <router-link to="/leyes" class="block px-3 py-2 text-gray-900 hover:text-red-600 focus:text-red-600 transition duration-300"> Leyes y Normas </router-link>
                    </li>
                    <li>
                        <router-link to="/education" class="block px-3 py-2 text-gray-900  hover:text-red-600 focus:text-red-600 transition duration-300"> Capacitaciones </router-link>
                    </li>
                    <li>
                        <router-link to="/news" class="block px-3 py-2 text-gray-900  hover:text-red-600 focus:text-red-600 transition duration-300"> Noticias </router-link>
                    </li>
                    <li>
                        <router-link to="/contact" class="block px-3 py-2 text-gray-900  hover:text-red-600 focus:text-red-600 transition duration-300"> Contactanos </router-link>
                    </li>
                </ul>
            </nav>
        </div>

        <!-- Mobile Menu Slider -->
        <div :class="{'translate-x-0': isMenuOpen, 'translate-x-full': !isMenuOpen}" class="fixed top-0 right-0 h-full w-64 bg-white shadow-lg transform transition-transform duration-300 ease-in-out  lg:hidden z-50">
            <div class=" bg-red-500  flex justify-between items-center p-2  ">
                <h2 class="text-xl font-bold text-gray-100">Menu</h2>
                <button @click="toggleMenu" class="text-gray-100 focus:outline-none">
                    <i class="fas fa-times"></i>
                </button>
            </div>
            <nav class="px-4 py-2">
                <ul class="space-y-4">
                    <li>
                        <router-link to="/" @click="toggleMenu" class="block text-gray-900 hover:no-underline  transition duration-300">Inicio</router-link>
                    </li>
                    <li @click="toggleSubmenu">
                        <div class="flex items-center cursor-pointer">
                            <span class="text-gray-900"> Institucional </span>
                            <i :class="{ 'rotate-180': submenuOpen }" class="fas fa-angle-down text-gray-600 mr-1 transition-transform transform px-4"></i>
                        </div>
                        <!-- Submenu Institucional -->
                        <ul v-if="submenuOpen" class="mt-2 space-y-2 pl-4">
                            <li><router-link to="/history" @click="toggleMenu" class="block text-gray-900 hover:no-underline transition duration-300">Sobre Nosotros</router-link></li>
                            <li><router-link to="/board" @click="toggleMenu" class="block text-gray-900 hover:no-underline transition duration-300">Comision Directiva</router-link></li>
                            <li><router-link to="/registration" @click="toggleMenu" class="block text-gray-900 hover:no-underline transition duration-300">Matricula</router-link></li>
                        </ul>
                    </li>
                    <li>
                        <router-link to="/leyes" @click="toggleMenu" class="block text-gray-900 hover:no-underline  transition duration-300">Leyes y Normas</router-link>
                    </li>
                    <li>
                        <router-link to="/education" @click="toggleMenu" class="block text-gray-900 hover:no-underline transition duration-300">Capacitacion</router-link>
                    </li>
                    <li>
                        <router-link to="/news" @click="toggleMenu" class="block text-gray-900 hover:no-underline transition duration-300">Noticias</router-link>
                    </li>
                    <li>
                        <router-link to="/contact" @click="toggleMenu" class="block text-gray-900 hover:no-underline transition duration-300">Contacto</router-link>
                    </li>
                </ul>
            </nav>

        </div>
    </header>
</template>

<script>
export default {
    data() {
        return {
            isMenuOpen: false,
            activeSubmenuIndex: null,
            isMouseOver: false,
             submenuOpen: false,
        };
    },
    methods: {
        toggleMenu() {
            this.isMenuOpen = !this.isMenuOpen;
        },
        showSubmenu(index) {
            this.activeSubmenuIndex = index;
            this.isMouseOver = true;
        },
        hideSubmenu() {
            this.activeSubmenuIndex = null;
            this.isMouseOver = false;
        },
        isSubmenuVisible(index) {
            return this.activeSubmenuIndex === index;
        },
        toggleSubmenu() {
            this.submenuOpen = !this.submenuOpen;
        },
    }
}
</script>

<style>

</style>

<template>
<div class="">
  <div class="container mx-auto flex justify-center lg:p-6">
    <!-- Contenido principal -->
    <div class="w-full lg:w-full xl:w-4/5 py-2 lg:py-4">
      <div class="px-1 lg:px-8 flex flex-col lg:flex-row lg:space-x-8">
        <!-- Sección de texto -->
        <div class="flex flex-col items-center justify-center  lg:w-full mb-6 lg:mb-0  lg:items-start text-center lg:text-left">
          <h1 class="text-4xl md:text-5xl lg:text-5xl xl:text-5xl font-bold">COPEF</h1>
          <h1 class="text-3xl md:text-4xl lg:text-4xl xl:text-4xl font-bold">SALTA</h1>
          <h2 class="text-base md:text-lg lg:text-base xl:text-xl font-bold">Colegio de Profesionales de Educación Física de Salta</h2>
          <div class="py-8">
            <router-link to="/registrationdownload"  class="bg-red-500 hover:bg-blue-700 text-white font-bold py-4 px-8 rounded-full shadow-lg">
            Descargar PDF de Matrícula
            </router-link>
          </div>
        </div>
        <div class="w-full flex justify-end items-end sm:justify-center">
            <img src="@/assets/images/educacion-fisica.jpg" alt="Imagen de fondo" class="inset-0 rounded-lg object-cover shadow-lg h-80  md:h-120 xl:h-120  ">
        </div>
      </div>
    </div>
  </div>
</div>


<div class="bg-rose-50">
  <CardNews/>
</div>
<div>
  <CardEducation/>
</div>

</template>

<script>
import CardNews from '@/components/home/CardNews.vue';
import CardEducation from '@/components/home/CardEducation.vue'


export default {
  name: 'Dashboard',
  components: { CardNews ,CardEducation
  },
  data() {
    return {
    };
},
  methods: {
  },
}
</script>
